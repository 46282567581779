// @flow

import React from "react";
import isNull from "lodash/isNull";
import {
	DiBaseErrorMessage,
	DiBaseWarnMessage,
	DiTooltip,
	DiTooltipTrigger,
	DiTooltipView,
	TooltipTypeEnum,
} from "@deepintent/react-component-lib-core";
import { diDayjs } from "@deepintent/react-utils";
import { getDateTimeString } from "@deepintent/react-component-lib";
import type { DiDayjs } from "@deepintent/react-utils";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import Constant from "./Constants";
import DashboardConstant from "./DashboardConstants";
import type { ValidationInfo } from "../types/validationInfo";
import type { InventoryStats, KeyValueObject, MetaData, TagType } from "../types";
import type { DateRange } from "../components/Dashboard/MetricCharts/MetricChartPanel";

export function hasOwnProperty(object: Object, field: any) {
	return Object.prototype.hasOwnProperty.call(object, field);
}

export function isArrayNotEmpty(arr: Array<any>) {
	return arr && Array.isArray(arr) && arr.length > 0;
}

const validateCookieName = (value: string) => {
	const cookieNameRegex = /[a-zA-Z0-9&!%=+#|$_.-]/g;
	const unmatchedCharacters = value.replace(cookieNameRegex, "");
	return unmatchedCharacters.length === 0;
};

export const cookieNameValidator = {
	level: "ERROR",
	validator: validateCookieName,
	errorMessage: `Cookie Name must not contain a separator character like ( ) < > @ , ; : \ " / [ ] ? = { }`,
};

// returns the initals of a string. Mainly useful for using with DiAvatar
export function getInitials(name: string) {
	const parts = name.split(" ");
	const intials: string = parts.reduce((acc, part) => {
		if (part.length > 0 && part !== "") {
			return acc + part[0];
		}
		return `${acc}`;
	}, "");
	return intials;
}

export function getValidDateForConversion(date: string | Date | DiDayjs = diDayjs()) {
	if (["date", "string"].includes(typeof date)) {
		return diDayjs(date);
	}

	return date;
}

/** Adds days to given date */
export function addDaysToDate(date: DiDayjs = diDayjs(), days: number = 30) {
	const refDate = getValidDateForConversion(date);

	return refDate.add(days, "days");
}

export const getFiltersFromVariables = (variables: Object, requiredFilterCriteria: Array<string>) => {
	let filterCriteria = [];

	if (variables) {
		const { filter } = variables;
		if (filter && filter.filterCriteria && Array.isArray(filter.filterCriteria)) {
			filterCriteria = filter.filterCriteria;

			if (requiredFilterCriteria && Array.isArray(requiredFilterCriteria) && requiredFilterCriteria.length) {
				filterCriteria = [];

				filter.filterCriteria.forEach((criteria) => {
					if (requiredFilterCriteria.includes(criteria.key)) {
						filterCriteria.push(criteria);
					}
				});
			}
		}
	}

	return { filterCriteria };
};

export const getFilterFromVariables = (variables: Object, requiredFilterCriteria: string) => {
	let filterCriteria = null;

	if (variables) {
		const { filter } = variables;
		if (filter && filter.filterCriteria && Array.isArray(filter.filterCriteria) && filter.filterCriteria.length) {
			if (requiredFilterCriteria) {
				filter.filterCriteria.forEach((criteria) => {
					if (requiredFilterCriteria === criteria.key) {
						filterCriteria = criteria;
					}
				});
			}
		}
	}

	return filterCriteria;
};

export function capitalizeFirstLetter(str: string) {
	if (!str) {
		return "";
	}

	return str.charAt(0).toUpperCase() + str.slice(1);
}

export function camelCaseString(str: string) {
	if (!str) {
		return "";
	}

	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function getFileNameFromEvent(event: SyntheticInputEvent<HTMLInputElement>) {
	let fileName = "";
	if (event.currentTarget && event.currentTarget.value) {
		const filePath = event.currentTarget.value.split("\\");
		if (Array.isArray(filePath) && filePath.length) {
			fileName = filePath[filePath.length - 1];
		}
	}

	return fileName;
}

export function parseApiValidationResponse(validation: ValidationInfo): {
	errors: Array<DiBaseErrorMessage>,
	warnings: Array<DiBaseWarnMessage>,
} {
	const errors: Array<DiBaseErrorMessage> = [];
	const warnings: Array<DiBaseWarnMessage> = [];

	if (validation && validation.errors) {
		const validationErrorsKeys = Object.keys(validation.errors);
		validationErrorsKeys.map((error) => {
			errors.push(new DiBaseErrorMessage(validation.errors[error]));
		});
	}

	if (validation && validation.warnings) {
		const validationWarningsKeys = Object.keys(validation.warnings);
		validationWarningsKeys.map((warning) => {
			warnings.push(new DiBaseWarnMessage(validation.warnings[warning]));
		});
	}

	return {
		errors,
		warnings,
	};
}

export function formatEnumValues(displayValue: string) {
	if (displayValue != null) {
		const d = displayValue.toLowerCase().split("_");
		let r = "";
		if (d != null && d.length > 1) {
			for (let i = 0; i < d.length; i++) {
				r = `${r} ${capitalizeFirstLetter(d[i])}`;
			}
			return r.trim();
		}
		if (d.length === 1) {
			return capitalizeFirstLetter(d[0]);
		}
		return "-";
	}
	return "-";
}

export default function getFilterCriteria(key: string, value: Object, operator: string) {
	if (key != null && value != null && operator != null) {
		return {
			key,
			value,
			operator,
		};
	}
	return null;
}

export function getQueryStringValue(location: Object, key: string) {
	return decodeURIComponent(
		location.search.replace(
			new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&")}(?:\\=([^&]*))?)?.*$`, "i"),
			"$1"
		)
	);
}

export function formatBytes(bytes: number, decimals: number) {
	if (bytes === 0) return "0 Bytes";
	const k = 1024;

	const dm = decimals <= 0 ? 0 : decimals || 2;

	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function getNumberWithThousandsSeparator(num) {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function getSingularString(value: string) {
	if (value.toLowerCase().endsWith("s")) {
		return value.slice(0, value.length - 1);
	}
	return value;
}

function getPluralString(value: string) {
	if (value.toLowerCase().endsWith("s")) {
		return value;
	}
	return value.concat("s");
}

function getInventoryString(key: string, value: number) {
	const isSingularString = value === 1;
	if (isSingularString) {
		return `${value} ${getSingularString(key)}`;
	}
	return `${value} ${getPluralString(key)}`;
}

/**
 * Util to return a key value pair in a delimited display format
 *
 * @param stats - An object with a dimension and its count
 * @param delimiter
 * @returns {string}
 */
export function formatStatsObject(stats: InventoryStats, delimiter: string = ",") {
	let displayValue = "";

	if (isEmpty(stats)) {
		return displayValue;
	}

	Object.keys(stats).forEach((key) => {
		const isStatsValueNonZero = parseInt(stats[key]) > 0;
		if (isStatsValueNonZero) {
			if (!isEmpty(displayValue)) {
				displayValue += delimiter.concat(" ");
			}
			displayValue += getInventoryString(key === "enewsletter" ? "e-Newsletters" : key.toLowerCase(), stats[key]);
		}
	});

	return displayValue;
}

/**
 * Util to calculate total statistics of a given key value pair.
 *
 * @param stats - An object with a dimension and its count
 * @returns {number} - Total count of all the dimensions
 */
export function getTotalStatsCount(stats: InventoryStats) {
	let totalCount = 0;
	Object.keys(stats).forEach((statsKey) => {
		totalCount += isNaN(parseInt(stats[statsKey])) ? 0 : parseInt(stats[statsKey]);
	});

	return totalCount;
}

export function downloadMarkup(markUp: string, name: string, fileType?: string, tagType?: string) {
	const tagName = tagType ? "%tagType%_tag_%name%" : "marketmatch_adtag_%name%";
	const fileExt = fileType || "html";
	const fileName = `${tagName}.${fileExt}`;
	const element = document.createElement("a");

	element.setAttribute("href", `data:text/plain;charset=utf-8,${encodeURIComponent(markUp)}`);
	element.setAttribute("download", fileName.replace(tagName, name));

	element.style.display = "none";
	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
}

export function getParameterValueFromPath(match: Object, paramName: string): ?string {
	if (match && match.params && hasOwnProperty(match.params, paramName)) {
		return match.params[paramName];
	}
	return null;
}

export function searchKeyMatch(value: string, searchKey: string): boolean {
	if (isEmpty(searchKey)) {
		return true;
	}

	if (isEmpty(value)) {
		return false;
	}

	return value.toLowerCase().indexOf(searchKey.toLowerCase()) > -1;
}

// TODO: Validate navlink
export function redirectTo(navLink: string, history: Object) {
	if (!navLink) {
		return;
	}
	if (history && history.push) {
		history.push(navLink);
	}
	if (navLink) {
		window.location.href = navLink;
	}
}

/**
 * Function that checks if a date falls behind x number of days
 * @param {string} date
 * @param {number} days -> x number of days
 * @returns
 */
export function isDateOlderThanDays(date: string, days: number = 30) {
	const startDate = diDayjs.utc(date).startOf("day");
	const now = diDayjs.utc().endOf("day");
	// take diff from today (considering today as well)
	const diffDay = Math.round(now.diff(startDate, "days", true));
	return diffDay > days;
}

export function getDiffDateRange(dateRange: DateRange) {
	const startDate = diDayjs(dateRange[0]);

	const endDate = diDayjs(dateRange[1]);
	const diffDay = endDate.diff(startDate, "days");
	return diffDay;
}

export function getMetricListForDimension(selectedDimension: string) {
	if (
		selectedDimension &&
		selectedDimension in DashboardConstant.DIMENSIONS &&
		"metricSet" in DashboardConstant.DIMENSIONS[selectedDimension]
	) {
		const metricSet = DashboardConstant.DIMENSIONS[selectedDimension].metricSet;
		if (metricSet === "publisher_metric") {
			return DashboardConstant.PUBLISHER_METRICS;
		}
		if (metricSet === "advertiser_metric") {
			return DashboardConstant.ADVERTISER_METRICS;
		}
	} else {
		Object.keys(DashboardConstant.METRICS);
	}
}

export function getNestedObject(nestedObj: Object, pathArr: string[]) {
	return pathArr.reduce((obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined), nestedObj);
}

export function getQueryString(queryParamsObject: Object) {
	if (queryParamsObject && typeof queryParamsObject === "object" && isArrayNotEmpty(Object.keys(queryParamsObject))) {
		let queryString = "";
		const params = Object.keys(queryParamsObject);
		params.map((key, index) => {
			index < params.length - 1
				? (queryString += `${key}=${queryParamsObject[key]}&`)
				: (queryString += `${key}=${queryParamsObject[key]}`);
		});

		return queryString;
	}

	return null;
}

/**
 * Util to get audience pixel markup code to download, copy or display audience tag.
 *
 * @param trackingId - Audience tracking id
 * @param tagType - Script or image tag
 * @returns {string} - Markup code
 */
export function getAudiencePixelMarkupCode(trackingId: string, tagType: TagType, customParams: ?KeyValueObject) {
	const trackingIdValue = trackingId || "";
	const tagTypeValue = tagType || "script";

	const defaultQueryString = `id=${trackingIdValue}`;
	const customQueryString = getQueryString(customParams);

	const queryString = customQueryString ? `${defaultQueryString}&${customQueryString}` : defaultQueryString;
	if (tagTypeValue === "script") {
		return `<script type="text/javascript">
	new Image().src
	= "https:${Constant.URLS.BEACON_BASE_URL}audience?${queryString}"
</script>`;
	}
	return `<img
	src="https:${Constant.URLS.BEACON_BASE_URL}audience?${queryString}"
	border="0" height="0" width="0"
>`;
}

export function getDisplayDate(displayValue: string, isUTC: boolean = true): string {
	if (isNull(displayValue)) {
		return "-";
	}
	const updatedDisplayValue = typeof displayValue === "string" ? displayValue.substring(0, 10) : displayValue;

	return getDateTimeString(Constant.LOCALE.DISPLAY_DATE_FORMAT, diDayjs(updatedDisplayValue), isUTC);
}

/**
 * Returns total rows from metaData
 * @param {Object} metaData
 * @returns {Number}
 */
export function getRowCount(metaData: MetaData) {
	return metaData && metaData.pageInfo && metaData.pageInfo.totalRows ? metaData.pageInfo.totalRows : 0;
}

/** converts json string to object */
export const parseJsonToObject = (jsonValue: string) =>
	typeof jsonValue === "string" && !isEmpty(jsonValue) ? JSON.parse(jsonValue) : {};

const SINGLE_LINE_PADDING = 8;

export function getIconWithToolTip(
	children: Node,
	text: string | Node,
	tooltipPlacement: string = "top",
	tooltipWrapperClass: string = "",
	tooltipViewClassName: string = "",
	tooltiptriggerClassName: string = "",
	viewPadding?: number = SINGLE_LINE_PADDING,
	tooltipBodyClassName: string = "",
	type: string = TooltipTypeEnum.DEFAULT.value
) {
	const viewClassName = classNames("globalTooltipViewClass", tooltipViewClassName);
	const tooltipBodyWrapper = classNames(viewClassName, tooltipBodyClassName);

	return (
		<DiTooltip type={type} className={tooltipWrapperClass}>
			<DiTooltipTrigger className={tooltiptriggerClassName}>{children}</DiTooltipTrigger>
			<DiTooltipView
				viewPadding={viewPadding}
				placement={tooltipPlacement}
				text={text}
				viewClassName={viewClassName}
				tooltipBodyClassName={tooltipBodyWrapper}
			/>
		</DiTooltip>
	);
}

export function isGreaterThanEqualTo(num: number, val: number | string) {
	return Number(val) >= num;
}

export function isLessThanOrEqualTo(num: number, val: number | string) {
	return Number(val) <= num;
}

export const normalizePath = (path = "") => {
	return path.replace(/\/+$/, "").replace(/^\/+/, "");
};

export const matchRoute = (currentPath = "", validPath) => {
	const currentSegments = normalizePath(currentPath).split("/");
	const validSegments = normalizePath(validPath).split("/");

	if (currentSegments.length !== validSegments.length) {
		return false;
	}

	return validSegments.every((segment, index) => {
		if (segment.startsWith(":")) {
			return true; // This is a parameter, so it always matches
		}
		return segment === currentSegments[index];
	});
};
